$font-url: '../fonts';
$gotham: "gotham";
@include font-face($gotham, $font-url, normal, normal, "gotham-book-regular");
@include font-face($gotham, $font-url, bold, normal, "gotham-bold");
@include font-face($gotham, $font-url, 800, normal, "gotham-black");
@include font-face($gotham, $font-url, 900, normal, "gotham-ultra");
@font-face {
    font-family: 'helvetica_neue_lt_com57Cn';
    src: url('../fonts/helveticaneueltcom-cn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica_neue_lt_com47LtCn';
    src: url('../fonts/helveticaneueltcom-ltcn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica_neue_lt_com67MdCn';
    src: url('../fonts/helveticaneueltcom-mdcn-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

//GLYPHICONS HALFLINGS
@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

//OPEN SANS
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'), url('../fonts/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans-Regular.ttf') format('truetype'), url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'), url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.woff2') format('woff2'), url('../fonts/OpenSans-Light.woff') format('woff'), url('../fonts/OpenSans-Light.ttf') format('truetype'), url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg'), url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-LightItalic';
    src: url('../fonts/OpenSans-LightItalic.eot');
    src: url('../fonts/OpenSans-LightItalic.woff2') format('woff2'), url('../fonts/OpenSans-LightItalic.woff') format('woff'), url('../fonts/OpenSans-LightItalic.ttf') format('truetype'), url('../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg'), url('../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Italic';
    src: url('../fonts/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic.woff2') format('woff2'), url('../fonts/OpenSans-Italic.woff') format('woff'), url('../fonts/OpenSans-Italic.ttf') format('truetype'), url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg'), url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../fonts/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'), url('../fonts/OpenSans-SemiBold.woff') format('woff'), url('../fonts/OpenSans-SemiBold.ttf') format('truetype'), url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg'), url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiBoldItalic';
    src: url('../fonts/OpenSans-SemiBoldItalic.eot');
    src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'), url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'), url('../fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg'), url('../fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'), url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf') format('truetype'), url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg'), url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-BoldItalic';
    src: url('../fonts/OpenSans-BoldItalic.eot');
    src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-BoldItalic.woff') format('woff'), url('../fonts/OpenSans-BoldItalic.ttf') format('truetype'), url('../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg'), url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('../fonts/OpenSans-ExtraBold.eot');
    src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBold.woff') format('woff'), url('../fonts/OpenSans-ExtraBold.ttf') format('truetype'), url('../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg'), url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-ExtraBoldItalic';
    src: url('../fonts/OpenSans-ExtraBoldItalic.eot');
    src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff'), url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'), url('../fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg'), url('../fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

//LEAGUE GOTHIC
@font-face {
    font-family: 'LeagueGothic-Regular';
    src: url('../fonts/LeagueGothic-Regular.eot');
    src: url('../fonts/LeagueGothic-Regular.woff2') format('woff2'), url('../fonts/LeagueGothic-Regular.woff') format('woff'), url('../fonts/LeagueGothic-Regular.ttf') format('truetype'), url('../fonts/LeagueGothic-Regular.svg#LeagueGothic-Regular') format('svg'), url('../fonts/LeagueGothic-Regular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueGothic-Italic';
    src: url('../fonts/LeagueGothic-Italic.eot');
    src: url('../fonts/LeagueGothic-Italic.woff2') format('woff2'), url('../fonts/LeagueGothic-Italic.woff') format('woff'), url('../fonts/LeagueGothic-Italic.ttf') format('truetype'), url('../fonts/LeagueGothic-Italic.svg#LeagueGothic-Italic') format('svg'), url('../fonts/LeagueGothic-Italic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueGothic-CondensedRegular';
    src: url('../fonts/LeagueGothic-CondensedRegular.eot');
    src: url('../fonts/LeagueGothic-CondensedRegular.woff2') format('woff2'), url('../fonts/LeagueGothic-CondensedRegular.woff') format('woff'), url('../fonts/LeagueGothic-CondensedRegular.ttf') format('truetype'), url('../fonts/LeagueGothic-CondensedRegular.svg#LeagueGothic-CondensedRegular') format('svg'), url('../fonts/LeagueGothic-CondensedRegular.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LeagueGothic-CondensedItalic';
    src: url('../fonts/LeagueGothic-CondensedItalic.eot');
    src: url('../fonts/LeagueGothic-CondensedItalic.woff2') format('woff2'), url('../fonts/LeagueGothic-CondensedItalic.woff') format('woff'), url('../fonts/LeagueGothic-CondensedItalic.ttf') format('truetype'), url('../fonts/LeagueGothic-CondensedItalic.svg#LeagueGothic-CondensedItalic') format('svg'), url('../fonts/LeagueGothic-CondensedItalic.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}