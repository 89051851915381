.header--site {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
    transition: top .5s;
    .top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 75px;
        box-shadow: 0 0px 12px rgba(0, 0, 0, .5);
        background: black;
        position: relative;
        z-index: 3;
        .home {
            border-right: 2px solid gray;
            width: 3em;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: inline-block;
            }
        }
        .title {
            padding-left: 4em;
            color: $brand-orange;
            font-weight: bold;
            span {
                text-transform: uppercase;
                font-weight: normal;
            }
        }
    }
    @include respond-to(desktop) {
        top: -120px;
        &.past-ctas {
            .header--site {
                top: 0;
            }
        }
    }
}

body.past-ctas {
    .header--site {
        top: 0;
    }
}