.card {
    padding: 4em 1em;
    background-color: $brand-lt-gray;
    color: $brand-black;
    p {
        line-height: 1.6em
    }
    h2,
    h3,
    p {
        color: $brand-black;
    }
    h2,
    p {
        &.pad-right {
            padding-right: 30%;
        }
    }
    h2 {
        font-weight: bold;
        text-transform: none;
        margin-bottom: 1rem;
        margin-top: 0;
    }
    h3 {
        font-weight: bold;
        width: $centeredWidth;
        &.text-center {
            margin: auto
        }
    }
    .image-wrapper {
        text-align: center;
        margin: 2em 0;
        img {
            max-width: 100%;
            display: inline-block;
            margin: auto;
        }
    }
    &.transparent {
        background: transparent;
        padding: 0 1em;
        h2,
        h3,
        p {
            color: white;
            &.teal {
                color: $brand-teal;
            }
        }
    }
    &--left,
    &--right {
        border-radius: 0;
    }
    &--left,
    &--right {
        border-top-left-radius: 30px;
        margin-right: -13px;
    }
    &--right--relapse {
        margin-top: 2em;
        margin-bottom: 2em;
        border-top-right-radius: 0;
        padding: 1em;
    }
    @include respond-to(desktop) {
        padding: 4em 4em 2em 4em;
        &.transparent {
            padding: 1em 4em 0 0;
        }
        &--left {
            margin-right: 0;
        }
        &--right {
            border-top-right-radius: 30px;
            margin-left: 0;
        }
        &--right--relapse {
            padding: 0;
            border-top-right-radius: 0;
        }
        .image-wrapper {
            img {
                max-width: $centeredWidth;
            }
        }
    }
}