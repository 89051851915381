// CSS to override bootstrap values. Copy and update the corresponding variable values from /node_modules/bootstrap/scss/_variables.scss
$container-max-widths: ( xl: 1030px);
$theme-colors: ( "primary": $brand-body, "secondary": #FFFFFF);
$body-bg: #FFFFFF;
$body-color: #020000;
$font-family-base: 'Lato',
Arial,
sans-serif;
$font-size-base: 1.875rem;
$link-hover-color: $brand-body;
// Glyphicons
$bootstrap-sass-asset-helper: false;
$icon-font-name: 'glyphicons-halflings-regular';
$icon-font-svg-id: 'glyphicons_halflingsregular';
$icon-font-path: '../fonts/';
// Breakpoitns
$grid-breakpoints: ( xs: 0, sm: 1024px, md: 1025px, lg: 1026px, xl: 1200px, xxl: 1900px);
$container-max-widths: ( sm: 990px, md: 1024px, lg: 1024.1px, xl: 1024.2px, xxl: 1024.3px);