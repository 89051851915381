.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 18% 20% 13% 12% 20%;
    img {
        align-self: center;
        width: 20%;
    }
    img:first-child {
        align-self: end;
    }
    img:nth-child(odd) {
        justify-self: end;
    }
    img:nth-of-type(2) {
        align-self: end;
    }
}

@media (hover:none),
(hover:on-demand) {
    .background {
        grid-template-rows: 17.6% 20% 13% 26% 14%;
        img {
            height: auto;
            width: 40%;
        }
    }
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
        .background {
            display: block;
            img {
                position: absolute;
            }
            img:first-child {
                right: 0;
                top: 18%;
            }
            img:nth-child(2) {
                left: 0;
                top: 38%
            }
            img:nth-child(3) {
                right: 0;
                top: 51%;
            }
            img:nth-child(4) {
                top: 63%;
            }
            img:nth-child(5) {
                right: 0;
                top: 83%;
            }
        }
    }
}