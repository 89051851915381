.button {
    // background: transparent;
    background: $brand-dark-purple-fade;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family--lato;
    font-size: pxToRem(26px);
    transition: background 0.35s;
    border: 1px solid $brand-teal;
    border-radius: $borderRadius;
    a {
        text-decoration: none;
        color: white;
        padding: 1em;
        font-size: 1.1rem;
        line-height: normal;
    }
    &--arrow {
        &--right {
            &::after {
                background-image: url('../img/arrow--button--right--white.png');
            }
        }
        &--left {
            &::before {
                background-image: url('../img/arrow--button--left--white.png');
            }
        }
        &--right,
        &--left {
            span {
                margin-right: 1rem;
            }
        }
    }
    @include respond-to(desktop) {
        &:hover,
        &.active {
            background: $brand-teal;
            a {
                color: $brand-body;
            }
        }
    }
}