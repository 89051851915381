.interstitial {
    visibility: hidden;
    display: grid;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    .interstitial-wrapper {
        margin: auto;
        background-color: $brand-lt-gray;
        color: $brand-black;
        max-width: 600px;
        padding: 50px;
        border-top-left-radius: 30px;
        filter: drop-shadow(7px 7px 20px rgba(0, 0, 0, 1));
        .button {
            text-transform: uppercase;
        }
        .interstitial-content {
            p {
                margin-bottom: 2rem;
                font-size: 1rem;
            }
            .button-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .button {
                    a {
                        font-size: inherit;
                        color: inherit;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    position: relative;
                    font-size: 0.8em;
                    color: $brand-lt-gray;
                    background-color: $brand-black;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border:none;
                    cursor: hand;
                    cursor: pointer;
                    &:hover,
                    &.active {
                        background: $brand-teal;
                        color: $brand-black;
                        path {
                            fill: #000
                        }
                    }
                    svg {
                        height: 10px;
                        position: absolute;
                        right: 20px;
                    }
                    @include respond-to(mobile) {
                        svg {
                            right: 10px;
                        }
                    }
                }
                .button:first-child {
                    margin-right: 30px;
                }
                .button:last-child {
                    margin-left: 30px;
                }
                @include respond-to(mobile) {
                    .button {
                        svg {
                            right: 10px;
                        }
                    }
                    .button:first-child {
                        margin-right: 5px !important;
                    }
                    .button:last-child {
                        margin-left: 5px !important;
                    }
                }
            }
        }
    }
    @include respond-to(mobile) {
        .interstitial-wrapper {
            max-width: 80%;
            padding: 5%;
        }
    }
}