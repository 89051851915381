.footer--site {
    font-size: pxToRem(16px);
    padding-bottom: 5em;
    section {
        margin-top: 3em;
    }
    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        list-style: none;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        li {
            a {
                color: white;
                text-decoration: underline;
            }
        }
    }
    .logo-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1em;
        img {
            width: 200px;
            height: auto;
            display: inline-block;
        }
    }
    .copyright {
        text-align: center;
        margin-top: 2em;
        span {
            margin-left: 1rem;
            &:first-of-type {
                display: block;
                margin-left: 0;
                line-height: normal;
            }
        }
    }
    @include respond-to(desktop) {
        ul {
            flex-direction: row;
            justify-content: space-evenly;
        }
        nav.menu {
            ul {
                li {
                    a {
                        margin-left: 1rem;
                        &:hover {
                            color: $brand-teal;
                        }
                    }
                }
            }
        }
        .logo-wrapper {
            align-items: flex-start;
            margin-top: 0;
        }
        .copyright {
            text-align: right;
            span {
                margin-left: 3em;
                &:first-of-type {
                    display: inline;
                }
            }
        }
    }
}