$freeze-size: 700px;
$keyboard-size: 870px;

div#mlkeyboard {
  font-family: Tahoma,Arial,sans-serif;
  width: 100%;
  position: absolute;
  top: 0;
  left: 2rem;
  ul, li {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  ul {
    background: #eee;
    list-style: none;
    font-size: 12px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: inline-block;

    @media screen and (min-width: $freeze-size) {
      width: auto;
      height: 230px;
      margin: 0 auto;
      padding: 0px;
      border-radius: 5px 5px 0 0;
    }

    li {
      float: left;
      margin: 0 0 0.5% 0.5%;
      width: 6%;
      text-align: center;
      background: #fff;
      border: 1px solid #f9f9f9;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-color: #e5e5e5;
      cursor: pointer;

      @media screen and (min-width: $freeze-size) {
        width: 56px;
        height: 56px;
        line-height: 56px;
        margin: 2px;
      }

      &.active {
        background-color: #E6FFD8;
        border-color: #6FDA54;
      }

      &:hover {
        background-color: #FFD488;
        border-color: #FFAE6E;
      }
    }

    #mlkeyboard-capslock, #mlkeyboard-tab, #mlkeyboard-left-shift {
      clear: left;
    }

    #mlkeyboard-backspace {
      @media screen and (min-width: $freeze-size) {
        width: 66px;
      }
    }

    #mlkeyboard-tab {
      width: 7%;

      @media screen and (min-width: $freeze-size) {
        width: 65px;
      }
    }

    #mlkeyboard-capslock {
      width: 12%;

      @media screen and (min-width: $freeze-size) {
        width: 106px;
      }
    }

    #mlkeyboard-return {
      width: 8%;

      @media screen and (min-width: $freeze-size) {
        width: 76px;
      }
    }

    #mlkeyboard-left-shift {
      width: 14%;

      @media screen and (min-width: $freeze-size) {
        width: 121px;
      }
    }

    #mlkeyboard-right-shift {
      width: 12%;

      @media screen and (min-width: 540px) {
        width: 123px;
        margin-right: 0;
      }
    }

    #mlkeyboard-space {
      clear: left;
      width: 98%;

      @media screen and (min-width: $freeze-size) {
        margin-top:  1rem;
        width: $keyboard-size;
      }
    }
  }

  ul.mlkeyboard-modifications {
    position: absolute;
    background: rgba(133, 133, 133, 0.6);
    border-radius: 5px;
    display: none;
    padding: 8px;

    @media screen and (min-width: $freeze-size) {
      height: 46px;
    }
  }
}