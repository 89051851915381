body {
    // line-height: 2.5rem;
    font-size: pxToRem(18px);
    background-color: black;
    overflow-x: hidden;
}

h1 {
    color: $brand-color;
    font-family: $font-family--lato;
    font-size: pxToRem(40px);
}

h2 {
    margin-bottom: pxToRem(15px);
    font-size: pxToRem(40px);
    color: $brand-teal;
    font-weight: bold;
    margin-top: 1.5em;
}

h3 {
    font-weight: bold;
    color: $brand-teal;
    font-size: pxToRem(24px);
    margin-bottom: pxToRem(10px);
}

p {
    line-height: 1.5;
    font-weight: normal;
    &.references {
        font-size: pxToRem(15px);
    }
}

sup {
    font-size: 65%;
    top: -.5rem;
    &.higher {
        top: -1rem;
    }
}

img {
    display: inline-block;
    max-width: 100%;
}

section {
    margin-top: 5em;
    &:first-of-type,
    &:nth-of-type(2) {
        margin-top: 0;
    }
    &.smallergap {
        margin-top: 2em
    }
}

@media (hover:none),
(hover:on-demand) {
    section {
        &:nth-of-type(2) {
            margin-top: 3em;
        }
    }
}

.intro-copy {
    font-size: pxToRem(26px);
    @media(min-width: $mobile) {
        font-size: pxToRem(30px);
    }
}

.footnote {
    font-size: 0.7em;
    font-weight: 200;
}

.hanging-bullet {
    text-indent: pxToRem(-7px);
    padding-left: pxToRem(8px);
}

.mobile {
    @include respond-to(desktop) {
        display: none;
    }
}

.desktop {
    @include respond-to(mobile) {
        display: none;
    }
}

.hidden-mobile {
    @media(max-width: $mobile) {
        display: none;
    }
}

.hidden-desktop {
    @media(min-width: $mobile) {
        display: none;
    }
}

.desktop-break {
    display: inline;
    @media(min-width: $mobile) {
        display: block;
    }
}

.mobile-break {
    display: block;
    @media(min-width: $mobile) {
        display: inline;
    }
}

.open-sans {
    font-family: $font-family--lato;
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: 600;
}

.text-uppercase {
    text-transform: uppercase;
}

.color--brand {
    color: $brand-color;
}

.text-red {
    color: red;
}

.main--content {
    position: relative;
}

.nobreak {
    white-space: nowrap;
}

.no-padding--left {
    padding-left: 0;
}

.dots {
    position: relative;
    &::before {
        position: absolute;
        left: -1.5em;
        bottom: 0.6em;
        display: block;
        content: '';
        width: 100px;
        height: 60px;
        background-image: url(../img/icon--dots.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

.leftcolumn80 {
    // width: $leftColumnWidth;
    h2,
    h3,
    p {
        width: 84%
    }
}

.leftcolumn {
    h2,
    h3,
    p {
        width: $leftColumnWidth
    }
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

@include respond-to(mobile) {
    .leftcolumn80 {
        h2,
        p {
            width: 100%
        }
        h3 {
            width: 70%
        }
        h3.full-width-mobile {
            width: 100%;
        }
    }
    .leftcolumn {
        h2,
        h3,
        p {
            width: 100%;
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }
}