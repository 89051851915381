.moreinfo {
    z-index: 100;
    display: inline-block;
}

svg.moreinfoicon {
    z-index: 1;
    height: 30px;
    position: relative;
    top: -3px;
    margin-left: 10px;
    .iconbackground {
        fill: none
    }
    .iconforeground {
        fill: $brand-lt-gray
    }
    &:hover {
        .iconbackground {
            fill: $brand-teal
        }
        .iconforeground {
            fill: $brand-black
        }
        .stroke {
            fill: $brand-teal
        }
    }
}

#moreInfoToolTip {
    position: fixed;
    z-index: 501;
    left: 30px;
    top: 130px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: $brand-lt-gray;
    color: $brand-black;
    padding: 30px;
    line-height: 1.2em;
    max-width: 340px;
    pointer-events: none;
    filter: drop-shadow(7px 7px 20px rgba(0, 0, 0, 1));
}

@include respond-to(mobile) {
    #moreInfoToolTip {
        width: 80% !important;
        left: 10% !important;
        border-bottom-left-radius: 30px;
    }
}