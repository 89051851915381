.jqbtk-row  {
	text-align: center;
}
.jqbtk-container .btn {
	font-size: 1.5em;
	width: 2.2em;
	height: 2em;
	text-align: center;
	margin: 2px;
}
.jqbtk-container .jqbtk-space {
	width: 12em;
}
.jqbtk-container .jqbtk-shift {
	width: 4.4em;
}
.jqbtk-container .jqbtk-enter {
	width: 4em;
}
.popover {
	max-width: 800px;
}
.keyboard-container{
	overflow: hidden;
	position:relative;
}
.keyboard-container .popover{
	left:0px !important;
	top:0px !important;
	width:100%;
	height:100%;
	border:none;
}
