.cookie-tray {
    background-color: #dae5f1;
    position: fixed;
    font-size: 18px;
    color: #1a1036;
    z-index: 10;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    transition: height .5s;
    a {
        color: blue;
        text-decoration: underline;
    }
    .container {
        padding: 0 1em;
        .col-12 {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .close:not(:disabled) {
        text-shadow: none;
        font-size: 1rem;
        font-weight: bold;
        color: #1a1036;
        text-transform: uppercase;
        padding: 1em 1em;
        background: $brand-teal;
        border: 1px solid $brand-teal;
        opacity: 1;
        border-radius: 10px;
        margin-bottom: 1em;
        position: relative;
        width: 80%;
        text-align: center;
        &:after {
            position: absolute;
            right: 20%;
            top: 17px;
            height: 14px;
            width: 10px;
            background: url(../img/icon--carot.png) center center no-repeat;
            content: '';
            background-size: contain;
        }
    }
    &.visible {
        padding: 1em 0 0 0;
        height: auto;
    }
    @include respond-to(desktop) {
        p {
            margin-bottom: 0;
        }
        a {
            &:hover {
                cursor: pointer;
            }
        }
        .container {
            padding: 0 4em;
            .col-12:last-of-type {
                justify-content: flex-end;
            }
        }
        .close:not(:disabled) {
            padding: 1em 3em;
            width: 20%;
            &:hover {
                cursor: pointer;
                background: transparent;
                color: #1a1036;
                text-shadow: none;
                border-color: #1a1036;
            }
            &:after {
                right: 1em;
            }
        }
        &.visible {
            padding: 2em 0 0 0;
        }
    }
}