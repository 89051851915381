// jQuery Bootstrap keyboard overrides.
.jqbtk-container {
  .btn {
    font-size: 1em;
    width: 1.9em;
  }
  .jqbtk-space {
    width: 12em;
  }
  .jqbtk-shift {
    width: 4.4em;
  }
  .jqbtk-enter {
    width: 4em;
  }
}
.popover {
  margin-top: 8em;
}