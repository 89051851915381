.tabs {
    padding: 0;
    background: transparent;
    .nav-item {
        width: 50%;
        text-align: center;
        a {
            border-top-left-radius: pxToRem(30px);
            border: 2px solid $brand-teal !important;
            border-bottom: none !important;
            background: $brand-teal;
            font-weight: bold;
            font-size: 1.15em;
        }
    }
    .tab-content {
        background: white;
        padding: 1.25rem;
        border: 2px solid $brand-teal;
        border-style: none solid solid solid;
        p {
            padding-right: 11%;
        }
        .image-wrapper {
            text-align: center;
            img {
                width: 100%;
                min-width: 100%;
                display: inline-block;
            }
        }
    }
}