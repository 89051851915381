@include respond-to(mobile) {
    .page--home {
        background-position: 50% 1%;
        background-size: 200%;
        h2 {
            margin-top: 0;
        }
        .pull-right {
            text-align: right;
        }
        .navbar--wrapper {
            // display: none !important
            top: 0;
        }
        section.hero {
            height: auto;
            .disclaimer {
                font-size: 3vw;
                width: 100%;
                text-align: center;
                z-index: 500;
                @media (orientation: landscape) {
                    font-size: 2vw;
                }
            }
            .intro {
                margin: 141px 0 300px
            }
            .ctas {
                .cta {
                    &:after {
                        content: url(/img/arrow--button--right--white.svg);
                        width: 16px;
                        margin-left: -110px;
                        position: relative;
                        right: 8%;
                        margin-top: 0;
                        line-height: 1em;
                    }
                    a {
                        width: 90%;
                    }
                    .footnote {
                        text-indent: 0;
                    }
                }
            }
        }
        .triple-class {
            h2 {
                width: 70%;
            }
            h3 {
                margin-top: 0;
            }
            .icon-circle-arrows {
                top: -5%;
                right: 0;
                height: 20%;
                @media (orientation: landscape) {
                    top: -13%;
                    height: 30%;
                }
            }
            .triple-class-img {
                position: absolute;
                right: 0;
            }
        }
        .drug-conjugates {
            .cta-bar {
                width: 100%;
            }
        }
    }
}