.navbar--wrapper {
    height: 100px;
    width: 100%;
    position: relative;
    background: $nav-background;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    transition: height 1s;
    ul.home {
        position: absolute;
        width: 100%;
        padding-top: 1.875rem;
        li {
            @extend .button;
            text-align: center;
            &:first-of-type {
                @extend .dots;
            }
            a {
                text-transform: uppercase;
            }
        }
    }
    .navbar-toggler {
        img {
            height: 26px;
            width: 26px;
            position: absolute;
            &.text--collapsed {
                width: 45px;
            }
        }
    }
    &.opened {
        // ul.home{ display: none }
        .navbar-toggler {
            .text {
                &--collapsed {
                    display: none;
                    right: 0;
                }
                &--opened {
                    display: block;
                    right: 0;
                }
            }
            .lines {
                div {
                    visibility: hidden;
                }
            }
        }
        .container {
            display: block;
            height: auto;
        }
    }
    nav {
        display: flex;
        justify-content: center;
        padding-top: pxToRem(30px);
        ul {
            li {
                @extend .button;
                text-align: center;
                &:first-of-type {
                    @extend .dots;
                }
                a {
                    text-transform: uppercase;
                }
            }
        }
    }
    .container {
        // transition: height 0.35s;
        // display: none;
        // height: 0;
        overflow: hidden;
        background-color: $brand-teal;
    }
    .navbar-toggler {
        // display: flex;
        // flex-direction: row;
        // margin-top: pxToRem(16px);
        width: 100px;
        height: 26px;
        // margin-bottom: pxToRem(16px);
        position: absolute;
        right: 5%;
        top: 28px;
        .text {
            // font-size: pxToRem(24px);
            // text-transform: uppercase;
            // color: $brand-teal;
            // text-align: center;
            right: 0;
            &--opened {
                display: none;
            }
        }
        .lines {
            display: block; // hiding the hamburger
            div {
                visibility: visible;
                width: 30px;
                height: 3px;
                background: $brand-teal;
                border-radius: pxToRem(4px);
                margin-top: 6px;
                margin-bottom: 6px;
                transform: rotate(0deg) translate(0px);
            }
        }
        &:active,
        &:focus {
            outline: none;
        }
    }
}

@include respond-to(desktop) {
    .navbar--wrapper {
        height: 100px;
        .navbar-toggler {
            display: none;
        }
        .container {
            overflow: visible;
            display: block;
            background-color: transparent;
            nav {
                .navbar-dropdown {
                    .navbar-nav {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        li {
                            border: none;
                            width: 20%;
                            background: transparent;
                            a {
                                transition: color 0.35s;
                                border: 0px solid transparent;
                                width: 100%;
                                padding: 0;
                                height: 3.6em;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            &:hover,
                            &.active {
                                background: none;
                                a {
                                    color: $brand-teal;
                                    text-decoration: underline;
                                }
                            }
                            &:first-of-type {
                                a {
                                    border-radius: $borderRadius;
                                    border: 2px solid $brand-teal;
                                }
                                &:hover,
                                &.active {
                                    background: $brand-teal;
                                    a {
                                        color: $brand-body;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(mobile) {
    .active-section {
        div {
            display: none;
            width: 100%;
            text-align: center;
            background: $brand-teal;
            text-transform: uppercase;
            line-height: 2.5em;
            color: $brand-black;
            font-size: .75em;
            padding-top: 2px;
            &.active {
                display: block;
            }
        }
    }
    .navbar--wrapper {
        ul.home {
            li {
                background: $brand-teal;
                padding: 2% 26%;
                border: none;
                border-bottom: 2px solid $brand-lt-gray;
                a {
                    color: $brand-black;
                    margin: auto;
                    padding: 0;
                    font-weight: 700;
                    line-height: 0;
                }
                &:first-child {
                    padding: 3% 5%;
                    width: 54%;
                    height: 46px;
                    border: none;
                    border-radius: 10px;
                    margin-bottom: 16px;
                    left: 10%;
                    &:before {
                        width: 10%;
                        left: -14%;
                        top: 0;
                        @media (orientation: landscape) {
                            width: 6%;
                            left: -8%;
                        }
                    }
                    @media (orientation: landscape) {
                        padding: 0;
                    }
                }
            }
        }
        .container {
            padding-left: 0;
            padding-right: 0;
            nav {
                ul li {
                    border-radius: 0;
                    background: $brand-teal;
                    padding: 2% 26%;
                    border: none;
                    border-bottom: 2px solid $brand-lt-gray;
                    a {
                        color: $brand-black;
                        margin: auto;
                        padding: 0;
                        font-weight: 700;
                    }
                    &:first-child {
                        padding: 0 5%;
                        width: 54%;
                        height: 46px;
                        border: none;
                        border-radius: 10px;
                        margin-bottom: 16px;
                        left: 10%;
                        &:before {
                            width: 10%;
                            left: -14%;
                            top: 0;
                        }
                    }
                }
                background-color: $nav-background;
                .navbar-toggler-dropdown {
                    width: 100%;
                    .navbar-nav {
                        li:first-of-type {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}