@mixin font-face($font-family-name, $font-url, $font-weight, $font-style, $font-name) {
    @font-face {
        font-family: '#{$font-family-name}';
        src: url('#{$font-url}/#{$font-name}.eot');
        src: url('#{$font-url}/#{$font-name}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-url}/#{$font-name}.woff') format('woff2'),
        url('#{$font-url}/#{$font-name}.woff') format('woff'),
        url('#{$font-url}/#{$font-name}.ttf') format('truetype'),
        url('#{$font-url}/#{$font-name}.svg##{$font-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}