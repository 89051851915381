.cta-link {
    background: $brand-color;
    border-radius: pxToRem(60px);
    max-width: 82%;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    &--full-width {
        max-width: 100%;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-top: pxToRem(5px);
        padding-right: pxToRem(24px);
        padding-bottom: pxToRem(5px);
        padding-left: pxToRem(40px);
        color: #FFFFFF;
        font-family: $font-family--open-sans;
        font-size: pxToRem(14px);
        text-decoration: none;
        text-transform: uppercase;
        .arrow {
            margin-bottom: pxToRem(6px);
            margin-left: pxToRem(16px);
        }
    }
}

.cta-bar {
    @extend .dots;
    background: white;
    color: $brand-black;
    padding: .5rem 1rem;
    font-size: pxToRem(24px);
    font-weight: bold;
    text-align: center;
    border-bottom: 10px solid $brand-teal;
    margin: 25px auto;
    // max-width: 70%;
    line-height: 1.4;
    &:before {
        left: -4rem;
        bottom: .5em;
        height: 75px;
    }
}

.cta-orange {
    background: $brand-orange url(../img/bg--oncopeptides--orange--mobile.png) no-repeat bottom center;
    background-size: contain;
    color: white;
    font-size: pxToRem(25px);
    font-weight: bold;
    line-height: 1.3;
    padding: 2em 1em 4em 1em;
    @include respond-to(desktop) {
        background: $brand-orange url(../img/bg--oncopeptides--orange.png) no-repeat bottom center;
        padding: 3em 2.5em 3em 2em;
        background-size: contain;
    }
}

@include respond-to(mobile) {
    .cta-bar {
        text-align: left;
        padding-left: 20%;
        &:before {
            left: 5%;
            top: 5%;
            width: 10%;
        }
    }
}