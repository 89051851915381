ul.bullets {
    list-style: none;
    padding-left: .5rem;
    li {
        position: relative;
        line-height: 1.4;
        &:before {
            position: relative;
            left: -.5rem;
            top: .2rem;
            font-size: 1.5rem;
            line-height: 1;
            content: '\2022';
            display: inline;
            color: $brand-teal;
        }
    }
}

.list--icons {
    h4 {
        font-weight: bold;
        font-size: 4vw;
        padding-left: 1.5em;
        margin-bottom: 0;
        text-align: center;
        flex: 1;
        line-height: pxToRem(72px);
    }
    .list-item {
        border-bottom: 1px solid $brand-purple;
        &.no-border {
            border-bottom: none;
        }
    }
    .top {
        min-height: 4.5em;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .expand {
            background: url(../img/icon--plus.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1.5em;
        }
        &[aria-expanded="true"] {
            .expand {
                background-image: url(../img/icon--minus.png);
            }
        }
    }
    .icon {
        width: 100%;
        position: relative;
        p {
            padding: .5em 0;
        }
        a {
            font-weight: bold;
            text-decoration: underline;
        }
        &::before,
        &::after {
            display: block;
            position: absolute;
            content: '';
            height: 100%;
            top: 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &::before {
            width: 3.25em;
            left: 0;
            background-position: left center;
        }
        &--molecule {
            &:before {
                background-image: url(../img/icon--molecule.png);
            }
        }
        &--bone {
            &:before {
                background-image: url(../img/icon--bone.png);
            }
        }
        &--stem-cells {
            &:before {
                background-image: url(../img/icon--stem-cells.png);
            }
        }
        &--epigenetics {
            &:before {
                background-image: url(../img/icon--epigenetics.png);
            }
        }
        &--immune-escape {
            &:before {
                background-image: url(../img/icon--immune-escape.png);
            }
        }
        &--relapse {
            &:before {
                background-image: url(../img/icon--relapse.png);
            }
        }
        &--pdc,
        &--adc {
            padding-left: 100px;
        }
        &--pdc {
            &:before {
                background-image: url(../img/icon--pdc.png);
            }
        }
        &--adc {
            &:before {
                background-image: url(../img/icon--adc.png);
            }
        }
    }
    &.inline {
        display: flex;
        flex-direction: column;
        .top {
            min-height: 0;
        }
        h4 {
            margin-bottom: 0;
            font-size: 5vw;
            margin-top: .5em;
            line-height: 1.2;
        }
        p {
            font-size: .9em;
            line-height: 1.4;
        }
        .icon {
            &:before {
                top: -.5em;
            }
        }
    }
    &#accordion-relapse {
        .top h4 {
            font-size: 5vw;
        }
    }
    @include respond-to(desktop) {
        padding-left: 7%;
        padding-right: 7%;
        &#accordion-relapse {
            padding-right: 3%;
            .top h4 {
                font-size: pxToRem(20px);
            }
            .bottom {
                padding-bottom: 2em;
            }
        }
        &.inline {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            padding-right: 0;
            h4 {
                margin-top: 0;
                font-size: pxToRem(20px);
            }
            .list-item {
                width: 45%;
            }
            p {
                width: auto;
            }
            .icon {
                border-bottom: none;
            }
        }
        h4 {
            margin-left: 0;
            padding-left: 0;
            font-size: pxToRem(20px);
            text-align: center;
        }
        .icon {
            &:before {
                top: 0;
            }
        }
        .list-item:last-of-type {
            .icon {
                border-bottom: none;
            }
        }
    }
}