.page--home {
    //background-color: $brand-body-bg;
    //background: linear-gradient(180deg, rgba(28, 14, 64, 1) 0%, rgba(29, 16, 65, 1) 100%);
    background: #200b4c;
    color: white;
    section.hero {
        h1 {
            span {
                display: block;
            }
            .s1 {
                font-weight: bold;
                color: $brand-teal;
                font-size: 28px;
            }
            .s2,
            .s3 {
                color: white;
                text-transform: uppercase;
            }
            .s2,
            .s3 {
                font-size: 58px;
            }
        }
        .image-wrapper {
            margin-top: 6em;
            img {
                width: 100%;
            }
        }
        .animation {
            .col-12 {
                padding-left: 0;
                padding-right: 0;
            }
            &.mobile {
                video {
                    max-width: 100%;
                    margin-top: 5em;
                }
            }
        }
        .disclaimer {
            color: white;
            font-size: 14px;
            position: fixed;
            text-align: right;
            padding-top: .25em;
        }
        .intro {
            color: $brand-teal;
            line-height: normal;
            margin-bottom: 7em;
        }
    }
    .ctas {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: -1em;
        .cta {
            @extend .button;
            position: relative;
            margin-bottom: 1rem;
            text-align: left;
            a {
                padding: 1em 1.5em;
            }
            .footnote {
                position: absolute;
                font-size: .4em;
                line-height: normal;
                text-align: left;
                left: 0;
                bottom: -4.5em;
            }
            &.with-footnote {
                margin-bottom: 2em;
            }
        }
        &.wide {
            .cta {
                background-color: rgba(26, 16, 54, 1);
            }
        }
    }
    .relative {
        position: relative;
    }
    .image-wrapper {
        &.pull-right {
            margin-right: -13px;
            margin-bottom: 1rem;
        }
    }
    .phases--branched-evolution,
    .phases--linear-evolution {
        display: flex;
        flex-direction: row;
        position: relative;
        top: -3.5rem;
        .phase {
            h4 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: pxToRem(20px);
            }
            &--1 {
                width: 37%;
                ul {
                    margin-bottom: 0;
                }
            }
            &--2 {
                width: 35%;
                padding-right: 2em;
            }
            &--3 {
                text-align: right;
                width: 30%;
                * {
                    text-align: left;
                }
                p {
                    padding-right: 0;
                }
            }
        }
    }
    #linear-evolution {
        .image-wrapper {
            text-align: right;
            img {
                max-width: 90%;
                min-width: 0;
            }
        }
    }
    .card,
    .triple-class {
        .external {
            span {
                white-space: nowrap;
                &:after {
                    content: url(../img/linkicon.svg);
                    height: 16px;
                    width: 16px;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
    }
    .triple-class {
        h3 {
            margin-top: 2em;
        }
        .icon-circle-arrows {
            position: absolute;
            top: 10%;
            right: 10%;
        }
        .group-left {
            position: relative;
            .diagram {
                margin-bottom: 4em;
            }
        }
        .cta-bar {
            margin-top: 60px;
        }
        .external {
            color: inherit;
            text-decoration: underline;
            font-weight: 700;
            span {
                &:after {
                    content: url(../img/linkicon-white.svg);
                }
            }
        }
    }
    .drug-conjugates {
        h3 {
            width: 100%;
        }
        .image-wrapper {
            img {
                max-width: 100%;
            }
        }
        .cta-bar {
            width: 80%;
            font-size: pxToRem(25px);
            line-height: 1.3;
        }
    }
    @include respond-to(desktop) {
        #animation-canvas {
            position: absolute;
        }
        video {
            position: relative;
            z-index: -1;
            //height: 500px;
        }
        section.hero {
            h1 {
                margin-left: 2.5em;
                span {
                    max-width: 50%;
                }
                .s3 {
                    font-size: 83px;
                }
            }
            .container.animation {
                max-width: 1366px;
                margin: 0 auto;
                video {
                    width: 100%;
                    //height: 500px;
                    max-width: 100%;
                    object-fit: cover;
                }
            }
            .image-wrapper {
                margin-top: 0;
                &.pull-right {
                    margin-right: -20px;
                }
            }
            .disclaimer {
                position: initial;
                text-align: center;
                background-color: $nav-background;
                padding-top: .75em;
                p {
                    margin-bottom: .75em;
                }
            }
        }
        .ctas {
            flex-direction: row;
            margin-top: 0;
            margin-bottom: 4em;
            justify-content: space-evenly;
            .cta {
                width: 25%;
                margin-bottom: 0;
                text-align: center;
                a {
                    font-weight: bold;
                }
                .footnote {
                    bottom: -5.5em;
                }
                &.with-footnote {
                    margin-bottom: 0;
                }
            }
            &.wide {
                margin: .5em 0 1em;
                .cta {
                    background-color: rgba(26, 16, 54, 1);
                    width: 40%;
                    &:hover {
                        background-color: $brand-teal;
                    }
                }
            }
        }
        .list--icons a {
            &:hover {
                color: #23D7FF;
            }
        }
        .triple-class {
            h3 {
                margin-top: 2em;
            }
            .group-left {
                h2,
                h3,
                p {
                    width: inherit;
                }
                max-width: 72%;
                margin-bottom: 22em;
                .diagram {
                    position: absolute;
                    right: -19em;
                    top: 7em;
                    max-width: none;
                    width: 130%;
                }
            }
        }
    }
    /* Edge */
    @supports (-ms-ime-align:auto) {
        .ctas {
            justify-content: space-around;
        }
    }
    /* IE */
    @media screen and (-ms-high-contrast: none) {
        .ctas {
            justify-content: space-around;
        }
    }
}
