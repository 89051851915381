// CONFIG: Use this to configure the application's CSS.
// COLORS - Branding. Limit to main overarching elements.
$brand-color: $brand-orange;
$brand-accent-color: $brand-orange;
$brand-body-color--primary: $brand-body;
$brand-body-color--alternate: $brand-body;
$error-color: red;
// DIMENSIONS - Breakpoints
$mobile: map-get($grid-breakpoints, "sm");
// DIMENSIONS - Sizes
$mainContentWidth: map-get($container-max-widths, "xl");
$mainContentWrapperWidth: map-get($container-max-widths, "xl")+409;
// FONTS
$font-family--open-sans: 'OpenSans-Regular',
Arial,
sans-serif;
$font-family--open-sans--italic: 'OpenSans-Italic',
Arial,
sans-serif;
$font-family--open-sans--light: 'OpenSans-Light',
Arial,
sans-serif;
$font-family--open-sans--light--italic: 'OpenSans-LightItalic',
Arial,
sans-serif;
$font-family--open-sans--semibold: 'OpenSans-SemiBold',
Arial,
sans-serif;
$font-family--open-sans--semibold--italic: 'OpenSans-SemiBoldItalic',
Arial,
sans-serif;
$font-family--open-sans--bold: 'OpenSans-Bold',
Arial,
sans-serif;
$font-family--open-sans--bold--italic: 'OpenSans-BoldItalic',
Arial,
sans-serif;
$font-family--open-sans--extrabold: 'OpenSans-ExtraBold',
Arial,
sans-serif;
$font-family--open-sans--extrabold--italic: 'OpenSans-ExtraBoldItalic',
Arial,
sans-serif;
$font-family--league-gothic: 'LeagueGothic-Regular',
Arial,
sans-serif;
$font-family--league-gothic--italic: 'LeagueGothic-Italic',
Arial,
sans-serif;
$font-family--league-gothic--italic: 'LeagueGothic-Italic',
Arial,
sans-serif;
$font-family--league-gothic--condensed: 'LeagueGothic-CondensedRegular',
Arial,
sans-serif;
$font-family--league-gothic--condensed--italic: 'LeagueGothic-CondensedItalic',
Arial,
sans-serif;
$font-family--lato: 'Lato',
Arial,
sans-serif;